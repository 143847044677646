.wrapper {
    margin-bottom: 1rem;
}

.input {
    border: 1px solid #eee;
    display: block;
    width: 100%;
    font-size: 1rem;
    padding: 0.8rem;
    border-radius: 0.4rem;
    background: #eee;
    transition: 0.2s;
    margin-bottom: 0.5rem;
}

.input:focus,
.input:hover {
    outline: none;
    border-color:#68bd62;
    background: #fff;
    box-shadow: 0 0 0 2px #1f8470;
}

.label {
    display: block;
    font-size: 1rem;
    line-height: 1;
    padding-bottom: 0.5rem;
}

.erro {
    color: red;
    font-size: .875rem;
    margin-top: .25rem;
}

.checkmark {
    position: absolute;
    top: 0;
    left: 0;
    height: 25px;
    width: 25px;
    background-color: #eee;
    border-radius: 2px;
}

