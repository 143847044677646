.form {
    margin-bottom: 2rem;
}

.showPassword {
    display: flex;
    justify-content: initial;
    width: auto;
    height: auto; 
    margin-bottom: 1rem;
    position: relative;  
    align-items: center;
}



