@import url('https://fonts.googleapis.com/css2?family=Roboto&display=swap');

* {
    box-sizing: border-box;
}

body {
    margin:  0px;
    padding-top: 4rem;
    color: #333;
    --type-first: Helvetic, Arial, sans-serif;
    --type-second: 'Roboto', Georgia;
    font-family: var(--type-first);
    background-color: #FCFDF3;
    

}

h1, h2, h3, h4, p {
    margin: 0px;
}

ul, li {
    list-style: none;
    padding: 0;
    margin: 0px;
}

img {
    display: block;
    max-width: 100%;
}

button, input {
    display: block;
    font-size: 1rem;
    font-family: var(--type-first);
    color: #333;
}

a {
    text-decoration: none;
}

a:link {
    color: #333;;
 }
 
 a:visited {
     color: #333;
     
 }
 
 a:hover {
     color: #333;
    }
    
    a:active {
        color:#333;
 }

.container {
    max-width: 50rem;
    padding: 0 1rem;
    margin: 0 auto;
}

.title {
    font-family: var(--type-second);
    line-height: 1;
    font-size: 3rem;
    margin: 1rem 0;
    position: relative;
    z-index: 1;
    text-decoration: none;
}

.title::after {
    content: '';
    display: block;
    width: 1.5rem;
    height: 1.5rem;
    background: #68bd62;
    position: absolute;
    bottom: 5px;
    left: -5px;
    border-radius: 0.2rem;
    z-index: -1;
    
}

.animeLeft {
    opacity: 0;
    transform: translateX(-20px);
    animation: animeLeft .3s forwards;
}

@keyframes animeLeft {
    to {
        opacity: 1;
        transform: initial;
    }
}
