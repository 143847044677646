@import url("https://fonts.googleapis.com/css2?family=Lato:wght@300;400&display=swap%27");
* {
  margin: 0;
  padding: 0;
  list-style: none;
}

a:link {
  color: initial;
  text-decoration: none;
}

a:visited {
  color: initial;
}

aside {
  border-right: 3px solid #AAA;
  box-sizing: border-box;
  display: inline-block;
  font-size: 1.4rem;
  height: 100vh;
  padding-left: 1rem;
  padding-right: 0.5rem;
  padding-top: 2rem;
  transition: .5s;
  position: fixed;
  z-index: 2000;
}

aside #logo {
  box-sizing: border-box;
  padding-left: 0.5rem;
  width: 100%;
}
aside #logo img {
  height: 4rem;
  width: 70%;
}
aside #logo:hover {
  cursor: pointer;
}
aside .user {
  box-sizing: border-box;
  align-items: center;
  justify-content: center;
  display: flex;
  padding: 0.5rem;
  margin: 2% 0;
  margin-right: 1rem;
}
aside .user:hover {
  cursor: pointer;
}
aside .user .iniciais {
  box-sizing: border-box;
  background-color: #F2F8F4;
  border-radius: 100%;
  border: 5px solid #FCFDFC;
  filter: drop-shadow(0 3px 1px #c9c9c9);
  font-weight: bold;
  padding: 1rem 1rem;
  vertical-align: middle;
}
aside .user .infos {
  padding-top: 1rem;
  padding-left: 0.5rem;
}
aside .user .infos .cargo {
  font-weight: bold;
  font-size: 1rem;
}
aside .user .infos .user_name {
  font-weight: bold;
}
aside nav {
  display: flex;
  flex-direction: column;
  height: calc(100vh - 4rem);
  overflow-y: auto;
  overflow-x: hidden;
  padding-right: 0.1rem;
  position: relative;
}
aside nav .m-item {
  background-color: #FCFDF3;
  box-sizing: border-box;
  border-radius: 0;
  display: flex;
  flex-direction: row;
  padding: 1rem 1rem;
  padding-right: 0;
  width: 100%;
}
aside nav .m-item svg {
  display: inline;
  width: 2rem;
  position: absolute;
  z-index: 10;
}
aside nav .m-item p {
  box-sizing: border-box;
  line-break: strict;
  letter-spacing: 0;
  margin-left: 0.5rem;
  padding-left: 2rem;
}
aside nav .m-item:hover {
  background-color: #eeeeee;
  cursor: pointer;
  padding-left: 1.9;
}
aside nav .m-item:hover svg {
  transition: width 100ms, height 101ms;
  width: 2.1rem;
}
aside nav .m-logo {
  z-index: 1;
}
aside nav .Submenu-Item {
  display: flex;
  flex-direction: column;
  position: relative;
}
aside nav .Submenu-Item .d-item-drop {
  display: none;
}
aside .logout {
  background-color: #FFF;
  box-sizing: border-box;
  border-radius: 10px;
  display: flex;
  flex-direction: row;
  padding: 1rem 1rem;
  padding-right: 0;
  width: 10rem;
}
aside .logout svg {
  display: inline;
  width: 2rem;
  position: absolute;
  z-index: 10;
}
aside .logout p {
  box-sizing: border-box;
  line-break: strict;
  letter-spacing: 0;
  margin-left: 0.5rem;
  padding-left: 2rem;
}
aside .logout:hover {
  background-color: #eeeeee;
  cursor: pointer;
  padding-left: 1.9;
}
aside .logout:hover svg {
  transition: width 100ms, height 101ms;
  width: 2.1rem;
}

.dashboard {
  box-sizing: border-box;
  display: inline-block;
  height: 100vh;
  padding: 0 2rem;
  position: absolute;
  width: calc(100% - 19rem);
}
.dashboard .header {
  border-bottom: 1px solid #AAA;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  position: relative;
  width: 100%;
  padding-top: 1rem;
}
.dashboard .header h1 {
  padding-right: 5rem;
  font-size: 1.2rem;
}
.dashboard .header .searchArea {
  height: 100%;
  width: 100%;
  margin-bottom: 0.7rem;
  display: flex;
  align-items: center;
}
.dashboard .header .searchArea .filtersArea {
  width: 80%;
  display: flex;
  justify-content: space-between;
  padding: 0 1rem;
}
.dashboard .header .searchArea .submitButton {
  height: 70%;
}
.dashboard .header:after {
  content: "";
  position: absolute;
  width: 100%;
  bottom: 1px;
  z-index: -1;
  transform: scale(1);
  box-shadow: 0px 3px 3px 1px #AAA;
}
.dashboard #AreaGrafico, .dashboard #AreaHome, .dashboard #AreaProfile, .dashboard #AreaStyles {
  overflow-y: auto;
  overflow-x: hidden;
  height: calc(83vh - 1rem);
  position: relative;
}
.dashboard #AreaGrafico #iconsTable thead th, .dashboard #AreaHome #iconsTable thead th, .dashboard #AreaProfile #iconsTable thead th, .dashboard #AreaStyles #iconsTable thead th {
  background: gainsboro;
  padding: 1rem 1rem;
}
.dashboard #AreaGrafico #iconsTable td, .dashboard #AreaHome #iconsTable td, .dashboard #AreaProfile #iconsTable td, .dashboard #AreaStyles #iconsTable td {
  text-align: center;
}
.dashboard #AreaGrafico .buttons, .dashboard #AreaHome .buttons, .dashboard #AreaProfile .buttons, .dashboard #AreaStyles .buttons {
  display: flex;
  justify-content: space-around;
}
.dashboard #AreaGrafico .buttons .pizzaButton, .dashboard #AreaHome .buttons .pizzaButton, .dashboard #AreaProfile .buttons .pizzaButton, .dashboard #AreaStyles .buttons .pizzaButton {
  box-shadow: black 3px 3px 10px;
  border-radius: 0px;
  height: 50px;
  width: 40%;
}

/* MEU GRAFICO */
.bar:hover {
  fill: brown;
}

.pagination {
  display: flex;
  list-style: none;
}

.pagination li + li {
  margin-left: 1rem;
}

.pagination__item-active {
  background: none;
  font-weight: bold;
  border: none;
}

.pagination__item-active:focus {
  outline: none;
}

/*# sourceMappingURL=styles.css.map */
