.filter{
    width: 100%;
    height: auto;
    position: fixed;
    box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.1);
    display: none;
    padding: 1rem; 
    align-content: space-between;
    align-items: center;
    gap: 1rem;
    flex-direction: row;
    flex-wrap: wrap;
    z-index: 2001;
    top: 4rem;
    background-color: #FCFDF3;
}





