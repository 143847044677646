.error {
    width: 100%;
    height: 2rem;
    color: #F44336;
    display: grid;
    grid-template-columns: 0.4fr  7fr;
    background-color: #FFE4E1;
    border-radius: 0.3rem;
    text-align: center;
    margin-bottom: auto;
    margin-top: auto;
    padding: 0.5rem;
    
}

.error::before {
    background-image: url(../../assets/error.png);
    background-size: contain;
    background-repeat: no-repeat;
    border-radius: 0.3rem;
    display: block;
    content: '';
}

